import './App.css';
import Community from './Components/Community';
import Faqs from './Components/Faqs';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';
import styled from 'styled-components';
import {Routes, Route} from 'react-router-dom';
import AboutUs from './Pages/AboutUs';

const Wrapper = styled.div`
  //background-color: #000;
  //color: #fff;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;

`
function App() {
  return (
    <Wrapper>
      <Navbar/>
      <Routes>
        <Route path='/' element={
          <>
            <Hero/>
            <AboutUs/>
            <Community/>
            <Faqs/>
          </>
        }/>{/* 
        <Route path='/about' element={}/> */}
      </Routes>
      
      <Footer/>
      
    </Wrapper>
  );
}

export default App;
