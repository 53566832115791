import React from 'react'
import styled from 'styled-components'
import { AiOutlineForm } from 'react-icons/ai'
import {GrGroup} from 'react-icons/gr'
import {AiOutlineGlobal} from 'react-icons/ai'

const Container = styled.section`
    
    background-color: #F6F8FF;
    margin: 1rem 10vw 1rem 10vw;
    height: fit-content;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        margin: 1rem 10vw 1rem 10vw;
    }

    #join{
        width: 50%;
        align-self: left;
        
        @media (max-width: 768px) {
            width: 100%;
            //background-color: aqua;

            #join-card{
                width: 100%;
                min-height: 12rem;

            }
        }

    }
    
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    height: 100%;
    width: 100%;
    justify-self: left;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
    
`

const Title = styled.div`
    
    h3 { 
        margin: 2rem 0;
        font-size: 3rem;
        font-weight: 600;
        color: #000;
        width: 100%;
        text-align: left;
        
        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 24px;  
        }
    }
`
const CommunityContainer = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //background-color: aqua;
    width: 100%;

    @media(max-width: 768px){
        flex-direction: column;
        width: fit-content;
    }

    #offer{
        margin-bottom: 1rem;
        //background-color: aqua;
        padding-bottom: .5rem;
    }
    

`
const CommunityCard = styled.div`
    background-color: #f6f8ff;
    //color: #fff;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border: 1px solid #2C346B;
    border-radius: 2rem;
    display: block;
    min-height: 12rem;

    @media(max-width: 768px){
        width: 100%;
        margin-right: 0;

    }

    #icon {
        margin: 1rem 2rem;
    }

    h4{
        margin: 0rem 2rem;

    }

    p{
        margin: 2rem 2rem;
        font-weight: 300;
    }

    button{
        margin: 1rem 1rem 0rem 2rem;
        color: #f6f8ff;
        border-radius: 5px;
        border: none;
        background-color: #2B3367;
        font-size: 1.2rem;
        font-weight: 600;

        height: 50px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        a  {
            text-decoration: none;
            color: #f6f8ff;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            font-style: normal;
            font-weight: 400;
        }
    }

    a{
        margin-left: 2rem;
        font-weight: 400;
        font-style: italic;
        cursor: pointer;
    }
`

const Community = () => {
  return (
    <Container id='community'>
        <Title>
            <h3>The Community</h3>
        </Title>
        <Row id='join'>
            <CommunityContainer id='join-card'>
            <CommunityCard >
                <AiOutlineForm id='icon' size='2rem'/>
                <h4>Join the Community</h4>
                <button><a href='https://forms.gle/LGw8BQARCAvfkf8T7' target="_blank">Apply Now</a></button>

            </CommunityCard>
            </CommunityContainer>
        </Row>
        <Row id='second'>
        <CommunityContainer>
            
            <CommunityCard id='offer'>
                <GrGroup id='icon' size='2rem'/>
                <h4>What will the community offer?</h4>
                <p>
                    <ul>
                        <li>Closed platform to discuss queer issues and other relevant information</li>
                        <li>Understand problems through data (both qualitative and quantitative) and find appropriate next steps to lead towards the solution</li>
                        <li>Dedicated channels to all services of the Queer Plexus including but not limited to job board, company reviews, events, etc</li>
                        <li>Latest information on policies and laws already in place in different organizations to protect the LGBTQIA+ community</li>
                    </ul>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </p>
                
            </CommunityCard>
            
        </CommunityContainer>

        <CommunityContainer>
            <CommunityCard>
                <AiOutlineGlobal id='icon' size='2rem'/>
                <h4>Why fill the application form?</h4>
                <p>
                The application form is meant to ensure that the community formed is a safe space with active participation from all members. The form serves the following purpose:
                <br/>
<br/>1. We address you correctly at all times and understand your expectations.
<br/>2. We ensure the safety of all members by having information on who signs up. Read more on our policies for data privacy, maintaining a safe environment, and anonymity.
<br/>3. The biggest problem we want to solve is access to data. All of this information helps us make informed decisions to serve the community better.
<br/>
<br/>Read more on our policies for data privacy, maintaining a safe environment, and anonymity in our FAQ’s
</p>
            </CommunityCard>
        </CommunityContainer>
        </Row>

        
        
    </Container>
  )
}

export default Community