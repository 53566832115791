import React, {useState} from 'react'
import styled from 'styled-components'
import { Data } from '../Data/FAQ'
import {FiMinus, FiPlus} from 'react-icons/fi'

const Container = styled.section`
    
    //background-color: lawngreen;
    position: relative;
    display: flex;
    flex-direction: column;
    //background-color: aqua;

    margin: 1rem 10vw 5rem 10vw;
    height: 90vh;

    @media (max-width: 768px) {
        margin: 1rem 10vw .5rem 10vw;
    }

    @media (max-width: 400px) {
        margin: 1rem 10vw .5rem 10vw;
    }

`
const Title = styled.div`
    
    h3 { 
        margin: 2rem 0;
        font-size: 3rem;
        font-weight: 600;
        color: #000;
    }
`
const Accordion = styled.div`
    //background-color: red;
    
    position: absolute;
    width: 100%;
    top: 20vh;
    box-shadow: 2px 10px 35px 1px rgba(153,153,153,0.3);

    p{
        padding: 1rem 2rem;
    }

`
const Wrap = styled.div`

    border-radius: .5rem;
    background-color: #2C346B;
    display: flex;
    color: #f6f8ff;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;

    border-bottom: .5px solid #FBF366;
    
    span{
        margin-right: 1.5rem;
    }
    
    h4 {
        margin: 1rem 2rem;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: left;

        @media (max-width: 768px) {
            font-size: 1rem;

        }
    }
`
const Dropdown = styled.div`
    background-color: #f6f8ff;
`

const Faqs = () => {

    const [clicked, setClicked] = useState(false)

    const toggle = index => {
        if(clicked === index) {
            return setClicked(null)
        }
        setClicked(index)
    }

  return (

    

    <Container id='faqs'>
        <Title>
            <h3>FAQs</h3>
        </Title>
        <Accordion>
            {Data.map((item, index) => (
                <div>
                    <Wrap onClick={() => toggle(index)} key={index}>
                        <h4>{item.question}</h4>
                        <span>{clicked === index ? <FiMinus/> : <FiPlus/>}</span>
                    </Wrap>
                    {
                        clicked === index ? (
                            <Dropdown> 
                                <p>{item.answer}</p>
                            </Dropdown>
                        ): null
                    }                    
                </div>
            ))}
        </Accordion>


    </Container>
  )
}

export default Faqs