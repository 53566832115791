import React from 'react'
import styled from 'styled-components'
import QPLogo from '../images/qp-logo.svg'
import {Link} from 'react-router-dom'
import NavRight from './NavRight'
import Hamburger from './Hamburger'

const Container = styled.nav`
    background-color: #F6F8FF;
    //background-color: blue;
    display: flex;
    height: 15vh;
    font-weight: 400;
    z-index: 20;
    justify-content: space-between;
    top: 0;
    position: sticky;

    @media (max-width: 768px) {
        //background-color: blue;
        width: 100%;
        position: fixed;
    }
`
const Logo = styled.div`

    img{
        cursor: pointer;
        height: 100%;
    }

    @media (max-width: 768px) {
        height: 90%;
        width: 70%;
    }

    //background-color: red;
    height: 100%;
    width: 20%;
    margin-left: 10vw;
    justify-self: space-between;
    display: flex;
`
const Title = styled.h3`

    margin: 2rem 2rem;
    align-self: center;

    @media (max-width: 768px) {
        width: 50%;
        margin: 2rem 1rem 2rem 1rem;

    }
    color: #2C346B;
    width: 100%;
`





const Navbar = () => {
  return (
        
        <Container>
            <Logo>
                <Link to='/'><img src={QPLogo}alt="QP Logo" /></Link>
                <Title>
                    The Queer Plexus
                </Title>
            </Logo> 
            <Hamburger/>

        </Container>
    )
}

export default Navbar