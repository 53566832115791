import React, {useState} from 'react'
import styled from 'styled-components'
import { FiMinus,FiPlus } from 'react-icons/fi'
import {Routes, Route} from 'react-router-dom';
import RoadMap from '../images/Roadmap.svg'
    

const Container = styled.section`
    background-color: ${p=> p.theme.FNWhite};
    height: fit-content;
    margin: 10vh 10vw;

    @media (max-width: 768px) {
        margin: 10vh 5vw;
        #roadmap{
            height: fit-content;
            display: none;
        }
    }
`
const Title = styled.h2`
    
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 96px;
    color: ${p=> p.theme.FNGrey};

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 24px;
    }

`
const Content = styled.div`
    
    height: 100%;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0 0;
        //background-color: aqua;
        align-items: center;
        justify-content: space-evenly;
    }

    #vision,#who,#part,#com,#note{
        background-color: #8d86c9;
        color: #000;
    }

    #note{

        @media (max-width: 768px) {
            display: none;
        }
    }

    
    #mission,#roadmap,#goals{
        background-color: #FDF7EF;
    }

    @media(max-width: 768px){
        #who,#part,#goals{
            margin-top: 0;
            background-color: #FDF7EF;
        }
        #com,#mission{
            background-color: #8d86c9;
            margin-top: 0;
        }
        #road{
            //background-color: #fff;
            width: fit-content;
            min-height: 5rem;
        }

        #part,#mission{
            margin-top: -1rem;
        }
        #goals{
            margin-top: -3rem;
        }
        
    }
    
`
const Card = styled.div`
    //background-color: #FDF7EF;
    //background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    align-items: left;
    justify-content: top;
    padding: 2rem 3rem;
    border: 2px solid black;
    width: 100%;
    height: fit-content;
    margin: 0 .5rem;
    min-height: 18rem;

    @media (max-width: 768px) {
        margin: 1rem 0;
        width: fit-content;
    }
`


const CardImg = styled.img`
    height: 10rem;
    width: 10rem;
    
`
const CardTitle = styled.text`
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 58px;
    color: ${p=> p.theme.FNGrey};

    @media (max-width: 768px) {
        line-height: 32px;
        margin-bottom: 2rem;
    }
`
const Dropdown = styled.div`

    font-style: normal;
    padding-bottom: 1rem;

    img{
        height: 100%;
        width: 100%;

        @media(max-width: 768px){
            height: 100%;
            width: 100%;
        }

    }
`

function AboutUs() {

  return (
    <Container id='about'>
        <Title>
            About Us
        </Title>
        <Content>
            <Card id='vision'>
                <CardTitle>
                    Our Vision
                </CardTitle>
                <Dropdown>
                Queer Plexus is a workplace community for LGBTQIA+ members with the aim to <b><i>inspire, enable, and prepare</i></b> queer individuals for their workplace, by creating a safe space for young adults and working professionals; facilitating confidence building and increasing their chances of success in the professional front.                </Dropdown>
            </Card>
            <Card id='who'>
                <CardTitle>
                    Who are we?
                </CardTitle>
                <Dropdown>
                We are a workplace community which will offer insights, information and a network of queer professionals across industries. Everything we build is by the queer community with the support of allies. <i>Data, Privacy and Decentralisation</i> are at our core which pushes us forward to find solutions in partnership with community members and ally organisations.
                </Dropdown>
            </Card>
        </Content>
        <Content>
            <Card id='mission'>
                <CardTitle>
                    Our Mission
                </CardTitle>
                <Dropdown>
                We are a workplace community for all queer folx with the goal to ensure that members of our community are ready to tackle the workforce both emotionally and mentally with every resource that they require such as networking, skillsets, understanding needs of various organizations, etc. 
What sets us apart from our counterparts is the fact that we aim to take corporates to community and not the community to corporates. This initiative will be completely transparent and based on inputs from the community with multiple avenues to help the community at large such as company reviews, networking, information about courses etc. These things are open to change based on community feedback and community needs
                </Dropdown>
            </Card>
        </Content>
        <Content>
            <Card id='part'>
                <CardTitle>
                    Who can be a part of Queer Plexus?
                </CardTitle>
                <Dropdown>
                We welcome all members of the queer community- Young adults and working professionals who are planning their first moves in the real world and are looking for internships or wanting to explore different industries, recent graduates who have just started or looking to start their first role in the workplace, veterans who have been working in any industry for at least 2 years and more and lastly, Artists/ Small Businesses/ Enterprenuers.                </Dropdown>
            </Card>
            <Card id='com'>
                <CardTitle>
                    Community Inclusion
                </CardTitle>
                <Dropdown>
                The community is open to all queer people who are either in the workforce or planning to join the same. As of now, the community is not open for ally’s but that is something we are planning to consider in the future. If you are an ally who wants to help or share relevant resources you can reach out to us via our email ID hello@thequeerplexus.com or our instagram page. We are open to Networking and receiving sponsorship opportunities.                </Dropdown>
            </Card>
        </Content>
        <Content >
            <Card id='roadmap'>
                <Dropdown id='road'>
                    <img src={RoadMap}/>
                </Dropdown>
            </Card>
        </Content>
        <Content>
            <Card id='note'>
                <CardTitle>
                    Note from our founder
                </CardTitle>
                <Dropdown>
                I always wanted to be a musician, but as a young teenager, I also felt I would never be able to land a job in the corporate world because of my gender identity. I was often told to join a school as a music teacher but I was always ticked of by the idea. I always felt if I take up this career path, I will never be able to be myself completely. So I dodged the conversation. 
                <br/>
                <br/>
                I returned to India in 2017 post completing my degree in Music from Los Angeles.  I worked with an Music entertainment company for a short while. I really enjoyed working with them, and the founder was incredible. However, I wasn’t out about my gender expression back then. He supported my choice of being a performing musician, and constantly guided me. But there were those subtle hints to be more “girly”. I could never explain it to them or have a conversation around who I am, and that just impacted my association with them. I wanted to be more involved and work with them in other verticals of my professional life but at a deeper level, I didn’t feel comfortable to be in an environment I didn’t see myself as a fit. 
                <br/>
                <br/>
                I faced a similar experience in my next job where I was teaching in a school. Between that period, I received a call from an old acquaintance who wanted to onboard me to his startup. Initially, conversations were still with my dead name, but I took a leap of faith and decided to introduce myself as Jay about 2 months into joining their team. 
                It instantly lifted a load of my mind and my productivity drastically increased. That also gave me the courage to start my social transition.  
                <br/>
                <br/>
                Fast forward to today, I joined a class where I was informed there will be a group assignment. That fear was back in the form of anxiety in me. Meeting a bunch of unknows in an online setting and fearing they undermining me because of my gender identity, took a hit at me after a very long time. This got me thinking, how many people have felt the same way in a corporate job, or even before landing their first role. 
                <br/>
                <br/>
                <b>Jay Anand</b>
                <br/>
                Founder, Queer Plexus                
</Dropdown>
            </Card>
        </Content>
        <Content>
            <Card id='goals'>
                <CardTitle>
                    Our goals
                </CardTitle>
                <Dropdown>
                    We aim at:
                    <ul>
                        <li><b>Increasing visibility of the members of the community in the workforce</b> by creating a platform that helps both those currently in the workplace and those joining the workplace by connecting them to more and more corporates by utilizing our network</li>
                        <br/>
                        <li><b>To generate opportunities for the members of the community in the workplace</b> via networking and sharing job opportunities</li>
                        <br/>
                        <li><b>To connect the community to other people from the field</b> who are doing great work in the workforce in regards to the LGBTQIA+ community via our events and discord platform along with newsletters as well. And providing a safe environment for all members to network with other professionals and share various job opportunities.</li>
                        <br/>
                        <li><b>To get people from the entire spectrum to be an active part of any company’s workforce</b> using dedicated channels to talk more about the industry and role-specific information and providing relevant information, skills and other such resources to effectively function in the workplace.</li>
                        
                    </ul>
                </Dropdown>
            </Card>
            
        </Content>
    </Container>
  )
}

export default AboutUs