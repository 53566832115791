export const Data = [
    {
        question: "What is the membership application process?",
        answer: "Potential members are required to fill the form listed on our website under “join the community”. The form is extensive and will take about 5 minutes to answer. These forms are then screened and members who are verified are then sent an email with details to join. We would ask for additional information if required. Please follow the instructions for individual questions as highlighted infront of them."
    },
    {
        question: "Who can join?",
        answer: "Anyone from the queer community who is currently working-  both freshers and those who have been in the workplace for a while, along with students who are planning to join the workplace in future can join our community. We also welcome all queer owned businesses and artists to sign up."
    },
    {
        question: "How do we maintain anonymity? ",
        answer: "All members would have the choice to either share their preferred/given name or stay anonymous on discord. Each member will have to use the same email id to login to discord as used in the application form. In case you want to sign up from another email ID you’d have to inform us about the same via discord. The email IDs will be screened manually to track members who are joining and only those whose IDs are verified will be allowed to join the space. The process of verification would be automated eventually. No information will be shared or accessed by anyone except the team members who also sign an NDA. We will remove your records from our database on request if you ever decide to leave the community. Identity will only be revealed in case there is a legal issue due to any misconduct by a user and someone has reported the same. Guidelines for the same will be made available."
    },
    {
        question: "How do we maintain it as a safe space?",
        answer: "The forms filled by all potential members are carefully screened. A strict database of members is maintained making it easy to flag someone in case of any discrepancy or any issue that arises. All issues will be addressed immediately and action will be taken as and when required. You can also reach out to us in case of such instances."
    },
    {
        question: "How do we ensure data privacy?",
        answer: "The data provided by you will be tracked at the backend by the team members which would also include volunteers. The team members are required to sign a Non Disclosure agreement to ensure the information is not used outside the queer plexus community. The information provided will only be used to understand the community better and serve their purposes better. If at any time you want to leave the community for any reason upon request the data and information will be permanently deleted from our records."
    }
]