import React, {useState} from 'react'
import styled from 'styled-components'
import NavRight from './NavRight'

const Container = styled.div`
    width: 2rem;
    height: 2rem;
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    top: 2rem;
    right: 10vw;
    z-index: 20;
    display: none;


    @media (max-width: 768px) {
        display: flex;
        //background-color: green;
        right: 10vw;
    }

    div{
        width: 2rem;
        height: 0.25rem;
        background-color: ${({open}) => open ? '#fff' : '#333'};
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;

        :first-child{
            transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        :nth-child(2){
            transform: ${({open}) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({open}) => open ? 0 : 1};
        }
        :nth-child(3){
            transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

const Hamburger = () => {
  
    const [open, setOpen] = useState(false)


    return (
        <>
            <Container open={open} onClick={()=> setOpen(!open)}>
                <div></div>
                <div></div>
                <div></div>
            </Container>
            <NavRight open={open}/>
        </>
    
  )
}

export default Hamburger