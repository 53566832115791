import React from 'react'
import styled from 'styled-components'
import {AiOutlineTeam} from 'react-icons/ai'
import info1 from '../images/info-1.svg'
import info2 from '../images/info-2.svg'
import info3 from '../images/info-3.svg'
import {Link} from 'react-router-dom'

const Container = styled.section`
    position: relative;
    background-color: #F6F8FF;
    display: flex;
    font-weight: 400;
    align-items: center;

    @media (max-width: 768px) {
        //background-color: aqua;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin-top: 8rem;
        //background-color: aqua;
    }
`
const Left = styled.div`
    margin-left: 10vw;
    //background-color: red;
    height: 100%;
    width: 50%;
    flex: 2;
    justify-self: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
    }


    button {
        margin: 1rem;
        background-color: #2C346B;
        border-radius: 5px;
        border: none;
        color: #FFFFFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem;
        font-weight: 400;

        height: 50px;
        width: 50%;
        display: flex;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media(min-width: 768px) {
            display: none;
    }

    a{
        text-decoration: none;
        color: #FFFFFF;
    }
}
`
const Right = styled.div`
    //background-color: blue;

    height: fit-content;

    @media(max-width: 768px) 
    {
        //display: none;
        display: flex;
        flex-direction: column;
        margin-left: 10vw;
        margin-right: 10vw;
        width: 80%;
        height: fit-content;
    }

    margin-right: 10vw;
    height: 100%;
    width: 50%;
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        height: 100%;
        width: 100%;

        @media(max-width: 768px) {
        
            margin-top: 2rem;
        }
    }
`
const Title = styled.h1`
    margin: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: #000;
`
const Subtitle = styled.h2`
    margin: 1rem;
    width: 80%;
    font-size: 1rem;
    font-weight: 400;
    color: #000;
`
const Count = styled.div`
    margin: 1rem;
    font-size: .8rem;
    font-weight: 300;
    width:  fit-content;
    color: #000;
    display: flex;
    justify-content: left;
    align-items: center;

    #user-icon{
        font-size: 1.2rem;
        color: #2C346B;
    }

    p {
        padding-left: .5rem;
    }
` 


const Hero = () => {
  return (
    <Container id='hero'>
        <Left>
            <Title>Queer Workplace Community</Title>
            <Subtitle>The Queer Plexus is a community of people creating a safe space for young adults and working professionals enabling them to be more confident in self and hence increasing their chances of success.</Subtitle>
            <button><a href='https://forms.gle/LGw8BQARCAvfkf8T7' target="_blank">Apply Now</a></button>
            {/* <Count>
                <AiOutlineTeam id='user-icon'/>
                <p><b>1232</b></p>
                <p>active members</p>
            </Count> */}
        </Left>
        <Right>
            <img src={info1} alt='hero'/>
            <img src={info2} alt='hero'/>
            <img src={info3} alt='hero'/>
        </Right>
    </Container>
  )
}

export default Hero