import React, { useState } from 'react'
import {Link as ScrollLink} from 'react-scroll'
import {Link, useLocation} from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.ul`
        width: 100%;
        //background-color: bisque;
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;

        @media (max-width: 768px) {
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;
            background-color: #2C346B;
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            width: 300px;
            margin-top: 0;
            margin-bottom: 10vh;
            transition: transform 0.3s ease-in-out;
            transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};

        }

        li {
            font-size: 1rem; 
            font-weight: 400;
            cursor: pointer;

            @media (max-width: 768px) {
                color: white;
                font-size: 1.5rem;
                font-weight: 400;
                padding: 0.5rem 0;
                margin-left: 2rem;
            }
        }

        a{
            text-decoration: none;
            color: ${({open}) => open ? '#fff' : '#333'};
        }
    
`;

const Login = styled.li`
    //background-color: blue;
    height: 100px;
    width: 10rem;
    margin-right: 10vw;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }

    button {
        margin: 1rem 0rem 1rem .5rem;
        background-color: #2C346B;
        border-radius: 5px;
        border: none;
        color: #FFFFFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem;
        font-weight: 400;

        height: 50%;
        width: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    a{
        text-decoration: none;
        color: #FFFFFF;
        height: 100%;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const NavRight = ({location, open}) => {

    const [isOpen, setIsOpen] = useState(open);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    location = useLocation();


  return (
    
        <Container open={open} >
            {
                location.pathname === '/' ? (
                    <>
                        <li><ScrollLink to="hero" spy={true} smooth={true} offset={-100} duration={500} onClick={toggle}>Home</ScrollLink></li>
                        <li><ScrollLink to='about' spy={true} smooth={true} offset={-100} duration={500}>About Us</ScrollLink></li>
                        <li><ScrollLink to="community" spy={true} smooth={true} offset={-100} duration={500}>Community</ScrollLink></li>
                        <li><ScrollLink to="faqs" spy={true} smooth={true} offset={-100} duration={500}>FAQs</ScrollLink></li>
                        <li><ScrollLink to="footer" spy={true} smooth={true} offset={-100} duration={500}>Contact Us</ScrollLink></li>
                        <Login>
                            <button><a href='https://forms.gle/LGw8BQARCAvfkf8T7' target="_blank">Apply Now</a></button>
                        </Login>
                    </>
                    
                            
                ) : (
                    <>
                        <li><ScrollLink to="hero" spy={true} smooth={true} offset={-100} duration={500}>Home</ScrollLink></li>
                        <li><ScrollLink to='about' spy={true} smooth={true} offset={-100} duration={500}>About Us</ScrollLink></li>
                        <li><ScrollLink to="community" spy={true} smooth={true} offset={-100} duration={500}>Community</ScrollLink></li>
                        <li><ScrollLink to="faqs" spy={true} smooth={true} offset={-100} duration={500}>FAQs</ScrollLink></li>
                        <li><ScrollLink to="footer" spy={true} smooth={true} offset={-100} duration={500}>Contact Us</ScrollLink></li>
                        
                        <Login>
                            <button><a href='https://forms.gle/ZvBwDyHtgzfXfY857' target="_blank">Sign Up</a></button>
                        </Login>
                    </>
                )
            }  
        </Container>
  )
}

export default NavRight