import React from 'react'
import styled from 'styled-components'
import QPLogo from '../images/qp-logo.svg'
import {AiOutlineTwitter,AiOutlineLinkedin,AiOutlineInstagram} from 'react-icons/ai'
import {Link} from 'react-router-dom'

const Container = styled.div`
    background-color: #2C346B;
    display: flex;
    flex-direction: column;
    height: 50vh;
    color: white;

    @media (max-width: 768px) {
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        height: fit-content;
        //background-color: aqua;
        padding: 1rem 0;
        
    }

`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50%;
    margin-top: 2%;
    //border-bottom: 2px solid white;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
        margin-top: 0;
        align-items: top;
        justify-content: center;

    }
`
const RowBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 20%;
    margin-left: 10vw;
    margin-right: 10vw;
    //border-bottom: 2px solid white;

    @media (max-width: 768px) {
        display: none;
    }
`
const FooterLeft = styled.div`
    //background-color: red;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 50%;
    margin-left: 10vw;
    border-bottom: 1px solid wheat;

    @media (max-width: 768px) {
        width: fit-content;
        margin-left: 0;
        margin-top: 0rem;
        border-bottom: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;

    }


`
const FooterRight = styled.div`
    //background-color: blue;
    border-bottom: 1px solid wheat;
    display: flex;
    justify-content: right;
    align-items: right;
    color: white;
    height: 100%;
    width: 50%;
    margin-right: 10vw ;

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        border-bottom: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
`

const Socials = styled.div`
    margin: 1rem;
    //background-color: aqua;
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: space-between;
    width: 50%;

    @media (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;
        justify-content: space-evenly;

    }

    a{
        text-decoration: none;
        color: white;
    }

    svg{
        cursor: pointer;
    }

    
`
const Logo = styled.div`

    img{
        cursor: pointer;
        height: 100%;

        @media (max-width: 768px) {
            height: 100%;
            width: fit-content;
        }
    }

    @media (max-width: 768px) {
        height: 50%;
        width: fit-content;
        display: none;
    }

    //background-color: red;
    flex: 2;
    height: 100%;
    justify-self: left;
`
const Title = styled.h2`
    margin: 1rem;
    font-size: 2rem;
    flex: 3;
    font-weight: 600;
    color: #FBF366;
    left: 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 2rem 2rem 0 2rem;
    }
`
const Subtitle = styled.h2`
    margin: 1rem;
    font-size: 1rem;
    font-weight: 300;

    @media (max-width: 768px) {
        font-size: 0.8rem;
    }
`
const Join = styled.div`
    background-color: #dce8a5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 25%;
    width: 80%;
    position: absolute;
    left: 10vw;
    margin-bottom: 5%;
    z-index: 10;
    border-radius: 1rem;

    h1{
        margin-left: 5rem;
        color: #000;
    }

    p {
        margin-left: 5rem;
        color: #000
    }

    button {
        margin: 1rem 5rem;
        background-color: #2C346B;
        border-radius: 5px;
        border: none;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 400;

        height: 50px;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        a  {
            text-decoration: none;
            color: #fff;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        height: fit-content;
        width: 100%;
        position: relative;
        left: 0;
        margin-top: 5rem;
        margin-bottom: 10rem;

        h1{
            margin-left: 1rem;
            font-size: 1.5rem;
        }

        p {
            display: none;
        }

        button {
            margin: 1rem 0;
            height: 50%;
            width: 50%;
        }
    }

    
`
const Column = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: right;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

`


const Footer = () => {
  return (
    <Container id='footer'>
        <RowBlock>
            <Join>
                <span>
                    <h1>Want to Join the Community?</h1>
                    <p>Join our community of Queer professionals and stay updated on information relevant to LGBTQIA+ professionals</p>
                </span>
                <button><a href='https://forms.gle/LGw8BQARCAvfkf8T7' target="_blank">Apply Now</a></button>
            </Join>
        </RowBlock>
        
        <Row>
            <FooterLeft>
                <Logo>
                <Link to='/'><img src={QPLogo}alt="QP Logo" /></Link>
                </Logo>
                <Title>The Queer Plexus</Title>

            </FooterLeft>
            <FooterRight>
                <Column>
                    <Subtitle>
                        Phone: +91 97312 94730 <br></br>
                        Email: hello@thequeerplexus.com
                    </Subtitle>
                    <Socials>
                        <a href='https://twitter.com/thequeerplexus' target="_blank">
                            <AiOutlineTwitter size='1.8rem'/>
                        </a>
                        <a href='https://www.instagram.com/thequeerplexus/' target="_blank">
                            
                            <AiOutlineInstagram size='1.8rem'/>
                        </a>
                        <a href='https://in.linkedin.com/company/thequeerplexus' target="_blank">
                            
                            <AiOutlineLinkedin size='1.8rem'/>
                        </a>
                    </Socials>
                </Column>
            </FooterRight>
        </Row>
        <RowBlock>
            <Subtitle>Copyright ©2022 All Rights Reserved - The Queer Plexus</Subtitle>

        </RowBlock>
    </Container>
  )
}

export default Footer